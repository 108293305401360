import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
    /**
     * @description: PM个人数据分析
     * @return {void}
     */
    'POST/delivery/pm_achievement': (params: any, extra?: Extra) => {
        return axios(`/delivery/pm_achievement`, preParams(params, extra, 'post'))
    },
    /**
     * @description: PM部门管理数据看板
     * @return {void}
     */
    'POST/delivery/pm_department_efficiency': (params: any, extra?: Extra) => {
        return axios(`/delivery/pm_department_efficiency`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 列表-PM业绩目标
     * @return {void}
     */
    'POST/delivery_achievements/page': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 分配-PM业绩目标
     * @return {void}
     */
    'POST/delivery_achievements': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 更新-PM业绩目标
     * @return {void}
     */
    'PATCH/delivery_achievements': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 删除-PM业绩目标
     * @return {void}
     */
    'DELETE/delivery_achievements/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 小组列表(下拉框)
     * @return {void}
     */
    'GET/delivery_group/group_list': (params: any, extra?: Extra) => {
        return axios(`/delivery_group/group_list`, preParams({}, extra, 'get'))
    },
    /**
     * @description: 列表-团队成员
     * @return {void}
     */
    'POST/delivery/page': (params: any, extra?: Extra) => {
        return axios(`/delivery/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 新增-团队团员
     * @return {void}
     */
    'POST/delivery/org': (params: any, extra?: Extra) => {
        return axios(`/delivery/org`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 修改-团队成员
     * @return {void}
     */
    'PATCH/delivery/org': (params: any, extra?: Extra) => {
        return axios(`/delivery/org`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 删除-团队成员
     * @return {void}
     */
    'DELETE/delivery/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 列表-小组配置
     * @return {void}
     */
    'POST/delivery_group/page': (params: any, extra?: Extra) => {
        return axios(`/delivery_group/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 增加小组-小组配置
     * @return {void}
     */
    'POST/delivery_group': (params: any, extra?: Extra) => {
        return axios(`/delivery_group`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 编辑小组-小组配置
     * @return {void}
     */
    'PATCH/delivery_group': (params: any, extra?: Extra) => {
        return axios(`/delivery_group`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 编辑小组-小组配置
     * @return {void}
     */
    'DELETE/delivery_group/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery_group/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 列表-组员-小组配置
     * @return {void}
     */
    'GET/delivery_group/member': (params: any, extra?: Extra) => {
        return axios(`/delivery_group/member`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 新增-组员-小组配置
     * @return {void}
     */
    'POST/delivery_group_members': (params: any, extra?: Extra) => {
        return axios(`/delivery_group_members`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 更新-组员-小组配置
     * @return {void}
     */
    'PATCH/delivery_group_members': (params: any, extra?: Extra) => {
        return axios(`/delivery_group_members`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 删除-组员-小组配置
     * @return {void}
     */
    'DELETE/delivery_group_members/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery_group_members/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 团队(下拉框)
     * @return {void}
     */
    'GET/delivery/org_list': (params: any, extra?: Extra) => {
        return axios(`/delivery/org_list`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 组长(下拉框)
     * @return {void}
     */
    'GET/delivery_group/group_leader': (params: any, extra?: Extra) => {
        return axios(`/delivery_group/group_leader`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 组员(下拉框)
     * @return {void}
     */
    'GET/delivery/member': (params: any, extra?: Extra) => {
        return axios(`/delivery/member`, preParams(params, extra, 'get'))
    },
    /**
     * @description: HRBP(下拉框)
     * @return {void}
     */
    'GET/delivery/hrbp': (params: any, extra?: Extra) => {
        return axios(`/delivery/hrbp`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 列表-业绩标准
     * @return {void}
     */
    'POST/delivery_achievement_rules/page': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievement_rules/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 新增-业绩标准
     * @return {void}
     */
    'POST/delivery_achievement_rules': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievement_rules`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 更新-业绩标准
     * @return {void}
     */
    'PATCH/delivery_achievement_rules': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievement_rules`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 删除-业绩标准
     * @return {void}
     */
    'DELETE/delivery_achievement_rules/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievement_rules/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 启用/禁用
     * @return {void}
     */
    'PATCH/delivery_achievement_rules/enable': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievement_rules/enable`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 批量设置员工业绩
     * @return {void}
     */
    'POST/delivery_achievements/batch_set': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements/batch_set`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 批量删除-PM业绩目标
     * @return {void}
     */
    'DELETE/delivery_achievements/batch_deleted': (params: any, extra?: Extra) => {
        return axios(`/delivery_achievements/batch_deleted`, preParams(params, extra, 'delete'))
    },
    /**
     * @description: 项目订单查询
     * @return {void}
     */
    'POST/delivery/query_order': (params: any, extra?: Extra) => {
        return axios(`/delivery/query_order`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 语言对(下拉框)
     * @return {void}
     */
    'GET/delivery/languagePairs': (params: any, extra?: Extra) => {
        return axios(`/delivery/languagePairs`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 业务类型(下拉框)
     * @return {void}
     */
    'GET/delivery/businessType': (params: any, extra?: Extra) => {
        return axios(`/delivery/businessType`, preParams(params, extra, 'get'))
    },
    /**
     * @description: 项目订单-导出
     */
    'POST/delivery/export_order': (params: any, extra?: Extra) => {
        return axios(`/delivery/export_order`, preParams(params, extra, 'post'))
    },
}