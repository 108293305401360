// 组织架构模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/maintenance';


export default {
  /**
   * @description: 凭证科目_获取所有子科目全名(报销维护模块)
   * @return {array}
   */
  'GET/voucher_subject/child_full_name': () => {
    return axios.get(`/voucher_subject/child_full_name`)
  },
  /**
   * @description: 报销维护_下载
   * @return {array}
   */
  'POST/invoice_expense_categorys/download': (params: any, extra: Extra) => {
    return axios(`/invoice_expense_categorys/download`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 获取发票主体并归类
   * @return {array}
   */
 'GET/company/invoiceCompany': () => {
    return axios.get(`/company/invoiceCompany`)
  },
}