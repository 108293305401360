import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  DelDepartParams,
  CompanyItem,
  Oauth2,
  Login,
  TokenInfo,
  CompanyEnItem,
  CompanyAccountItem,
} from './model/systemModel';


export default {
  /**
   * @description: 删除部门
   * @param {string} departmentId 部门Id
   * @return {undefined}
   */
  'DELETE/departments/{departmentId}': (params: DelDepartParams, extra?: Extra) => {
    return axios.delete<void>(`/departments/${params.departmentId}`, extra)
  },

  /**
   * @description: 判断测试和生产环境
   * @return {undefined}
   */
  'GET/application/info': (params?: object) => axios.get<void>(`/application/info`),

  /**
   * @description: 获取所有公司列表(不分页，非树状)
   * @return {undefined}
   */
  'GET/company': (params?: object) => axios.get<CompanyItem[]>(`/company`),

  /**
   * @description: 获取所有公司列表(分类,不分页，非树状)
   * @return {undefined}
   */
  'GETcompany/active': () => axios.get<any[]>(`company/active`),

  /**
   * @description: 获取所有非注销公司列表(不分页，非树状)
   * @return {undefined}
   */
  'GET/company/active': (params?: object) => axios.get<CompanyItem[]>(`/company/active`),

  /**
   * @description: 发送短信
   * @param {string} phoneNumber 手机号码
   * @return {undefined}
   */
  'GET/users/send_sms': (params?: {purpose: string}, extra?: Extra) => {
    return axios.get<void>(`/users/send_sms`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 发送短信验证码(已登录状态)
   * @param {string} purpose 短信类型，SalaryConfirm：薪酬确认，CheckinConfirm： 考勤确认
   * @return {undefined}
   */
  'GET/sms/purpose': (params?: {purpose: string}, extra?: Extra) => {
    return axios.get<void>(`/sms/purpose`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 发送短信验证码(已登录状态)
   * @param {string} purpose 邮件类型，SalaryConfirm：薪酬确认，CheckinConfirm： 考勤确认
   * @return {undefined}
   */
  'GET/mail/send_code': (params?: object, extra?: Extra) => {
    return axios.get<{purpose: string}>(`/mail/send_code`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 绑定企业微信二维码地址
   * @return {undefined}
   */
  'GET/oauth2': (params?: object, extra?: Extra) => {
    return axios.get<Oauth2>(`/oauth2`)
  },

  /**
   * @description: 企微扫码登录所需信息
   * @param {string} url 扫码登录后重定向所需接口
   * @return {undefined}
   */
  'GET/oauth2/webInfo': (params?: {url: string}, extra?: Extra) => {
    return axios.get<any>(`/oauth2/webInfo`)
  },

  /**
   * @description: 登录接口
   * @param {string} username 用户名
   * @param {string} password 用户名
   * @return {undefined}
   */
  'POST/token': (params?: Login, extra?: Extra) => {
    return axios(`/token`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 万能登录
   * @param {string} userId 用户ID
   * @param {string} password 用户名
   * @return {undefined}
   */
  'POST/secret': (params?: {userId: number, password: string}, extra?: Extra) => {
    return axios(`/secret`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取用户信息（登陆后）
   * @return {undefined}
   */
  'GET/token/info': (params?: null, extra?: Extra) => {
    return axios.get<TokenInfo>(`/token/info`)
  },

  /**
   * @description: 预览文件
   * @return {undefined}
   */
  'GET/upload/download': (params?: any, extra?: Extra) => {
    return axios.get<any>(`/upload/download`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 预览文件
   * @return {undefined}
   */
  'GET/upload/preview': (params?: any, extra?: Extra) => {
    return axios.get<string>(`/upload/preview`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 根据公司id获取英文名
   * @param {number} id 公司ID
   * @return {undefined}
   */
  'GET/companies/enNames/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<CompanyEnItem[]>(`/companies/enNames/${params.id}`, extra)
  },

  /**
   * @description: 公司账户信息列表
   * @param {number} id 公司ID
   * @return {undefined}
   */
  'GET/company/bank_accounts/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<CompanyAccountItem[]>(`/company/bank_accounts/${params.id}`, extra)
  },

  /**
   * @description: 新增或更新公司银行账号信息
   * @return {undefined}
   */
  'POST/company/bank_account': (params: CompanyAccountItem, extra?: Extra) => {
    return axios(`/company/bank_account`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除公司账户信息
   * @return {undefined}
   */
  'DELETE/company/bank_accounts/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete(`/company/bank_accounts/${params.id}`, extra)
  },

  /**
   * @description: 上传公司账户文件
   * @param {number} id: 公司账户ID
   * @param {File} file: 公司账户文件
   * @return {undefined}
   */
  'POST/company/bank_account/upload': (params: {id: number, file: File}, extra?: Extra) => {
    return axios(`/company/bank_account/upload`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除公司账户文件
   * @param {number} id: 公司账户ID
   * @param {number} fileId: 公司账户文件ID
   * @return {undefined}
   */
  'POST/company/bank_account/delete': (params: {id: number, fileId: number}, extra?: Extra) => {
    return axios(`/company/bank_account/delete`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 下载公司账户文件
   * @param {number} id
   * @param {number} conpanyId: 公司ID
   * @return {undefined}
   */
  'POST/company/bank_account/download': (params: {id: number, conpanyId: number}, extra?: Extra) => {
    return axios(`/company/bank_account/download`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 设为默认银行
   * @param {number} id: 公司账户ID
   * @param {number} fileId: 公司账户文件ID
   * @return {undefined}
   */
  'PUT/company/bank_account/priority': (params: {id: number, fileId: number}, extra?: Extra) => {
    return axios(`/company/bank_account/priority`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 公司维护-获取公司详情
   * @param {number} id: 公司ID
   * @return {undefined}
   */
  'GET/companies/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<any>(`/companies/${params.id}`, extra)
  },

  /**
   * @description: 更改系统消息阅读状态
   * @param {number} id: 消息ID
   * @return {undefined}
   */
  'PATCH/sys_messages/status': (params: {id: number}, extra?: Extra) => {
    return axios(`/sys_messages/status`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 获取个人消息
   * @param {number} userId: 用户ID
   * @return {undefined}
   */
  'GET/sys_messages/oneself': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/sys_messages/oneself`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取公告消息
   * @param {number} userId: 用户ID
   * @return {undefined}
   */
  'GET/sys_messages/announcement': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/sys_messages/announcement`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 退出登录
   * @param {number} userId: 用户ID
   * @return {undefined}
   */
  'POST/logout': (params: {userId: number}, extra?: Extra) => {
    return axios(`/logout`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 下载营业执照或开票资料
   * @param {number} fileId: 文件ID
   * @return {undefined}
   */
  'GET/upload/download_file': (params: {fileId: number}, extra?: Extra) => {
    return axios.get(`/upload/download_file`, preParams(params, extra, 'get'))
  },
  /**
   * @description: 权限删除检查
   */
  'POST/users/privileges/delete/check': (params:any, extra?: Extra) => {
    return axios(`/users/privileges/delete/check`, preParams(params, extra, 'post'))
  },
}