import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {Validate} from './model/verificationModel';

export default {
  /**
   * @description: 勾销校验
   * @return {void}
   */
  'POST/verification/liquidate/validate': (params: any, extra?: Extra) => {
    return axios(`/verification/liquidate/validate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取流水绑定的订单
   * @param {array} isStr
   * @return {void}
   */
  'POST/financial_flows/orders_of_flow_product': (params: {idStr: number[]}, extra?: Extra) => {
    return axios(`/financial_flows/orders_of_flow_product`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取匹配的客户
   * @param {number} page
   * @param {number} size
   * @param {string} customerName
   * @param {Extra} extra
   * @return {void}
   */
  'POST/verification/match/customers': (params: {page: number, size: number, customerName: string}, extra?: Extra) => {
    return axios(`/verification/match/customers`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取匹配的订单
   * @param {number} page
   * @param {number} size
   * @param {array} customerIds 客户id
   * @param {number} orderSort 1:发票日期;2:开单日期;3:交付日期
   * @return {void}
   */
  'POST/verification/match/orders': (params: {page: number, size: number, customerIds: string[], orderSort: number}, extra?: Extra) => {
    return axios(`/verification/match/orders`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取匹配的流水
   * @param {number} page
   * @param {number} size
   * @param {array} customerIds 客户id
   * @return {void}
   */
  'POST/verification/match/flows': (params: {page: number, size: number, customerIds: string[]}, extra?: Extra) => {
    return axios(`/verification/match/flows`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 匹配信息返回
   * @param {array} customerIds 客户id
   * @param {array} flowIds 流水id
   * @return {void}
   */
  'POST/verification/match/info': (params: {customerIds: string[], flowIds: string[]}, extra?: Extra) => {
    return axios(`/verification/match/info`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 验证匹配信息
   * @param {array} orderNos 订单号
   * @param {array} flowIds 流水id
   * @return {void}
   */
  'POST/verification/match/validate': (params: {orderNos: string[], flowIds: number[]}, extra?: Extra) => {
    return axios(`/verification/match/validate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 计算匹配的流水
   * @param {array} flowIds 流水id
   * @return {void}
   */
  'POST/verification/match/compute/flow': (params: {flowIds: number[]}, extra?: Extra) => {
    return axios(`/verification/match/compute/flow`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 计算匹配的订单
   * @param {number} page
   * @param {number} size
   * @param {array} orderNos 订单号
   * @return {void}
   */
  'POST/verification/match/compute/order': (params: {orderNos: string[], page: number, size: number}, extra?: Extra) => {
    return axios(`/verification/match/compute/order`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建匹配余额(确认勾销)
   * @param {array} orderNos 订单号
   * @param {array} flowIds 流水id
   * @param {number} balance 余额
   * @param {string} balanceCurrency 余额币种
   * @return {void}
   */
  'POST/verification/match/create_balance': (params: {orderNos: string[], flowIds: number[], balance: number, balanceCurrency: string}, extra?: Extra) => {
    return axios(`/verification/match/create_balance`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取待验证的匹配信息
   * @param {number} page 页码
   * @param {number} size 每页大小
   * @param {object} originalFlowRange 原始流水范围
   * @param {object} handlingFeeRange 手续费范围
   * @param {object} financialExpensesRange 财务费用范围
   * @param {object} balanceRange 余额范围
   * @param {string} createTimeFrom 创建时间起
   * @param {string} createTimeTo 创建时间止
   * @param {boolean|null} badDebt 坏账
   * @param {string} invoiceNo 发票号
   * @param {string} orderNo 订单号
   * @param {string} operator 操作员
   * @param {string} currency 币种
   * @param {string} customer 客户
   * @param {string} customerId 客户ID
   * @param {string} flowId 流水ID
   * @param {string} bankName 银行名称
   * @param {array} costFrom 成本来源
   * @param {object} collectionAt 收款时间
   * @param {boolean} push 推送
   * @return {void}
   */
  'POST/verification/match/wait_validate': (params: Validate, extra?: Extra) => {
    return axios(`/verification/match/wait_validate`, preParams(params, extra, 'post'))
  },

  
  /**
   * @description: 财务审核
   * @param {number} handlingFee 手续费
   * @param {string} handlingFeeCurrency 币种
   * @param {number} balanceId id
   * @return {void}
   */
  'POST/verification/match/finance_validate': (params: {handlingFee: number, handlingFeeCurrency: string, balanceId: number}, extra?: Extra) => {
    return axios(`/verification/match/finance_validate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取预付款列表
   * @param {number} page 页码
   * @param {number} size 每页大小
   * @param {number} customerId 客户ID
   * @return {void}
   */
  'POST/verification/advance_payment/page': (params: {page: number, size: number, customerId: number}, extra?: Extra) => {
    return axios(`/verification/advance_payment/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新预付款
   * @param {number} id 预付款ID
   * @param {number} customerId 客户ID
   * @param {string} currency 币种
   * @param {number} amount 金额
   * @param {string} receiptDate 收款日期
   * @return {void}
   */
  'POST/verification/advance_payment/update': (params: {id: number, customerId: number, currency: string, amount: number, receiptDate: string}, extra?: Extra) => {
    return axios(`/verification/advance_payment/update`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建预付款
   * @param {number} customerId 客户ID
   * @param {string} currency 币种
   * @param {number} amount 金额
   * @param {string} receiptDate 收款日期
   * @return {void}
   */
  'POST/verification/advance_payment/create': (params: {customerId: number, currency: string, amount: number, receiptDate: string}, extra?: Extra) => {
    return axios(`/verification/advance_payment/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除预付款
   * @param {number} id 预付款ID
   * @return {void}
   */
  'delete/verification/advance_payment/delete/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/verification/advance_payment/delete/${params.id}`)
  }
}