import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 差旅费明细模板下载
   * @return {void}
   */
  'GET/invoice_expense_vouchers/travel/template': (params: any, extra?: Extra) => {
    return axios.get<any[]>(`/invoice_expense_vouchers/travel/template`, {responseType: 'blob'})
  },

  /**
   * @description: 差旅费明细模板导入
   * @param {file}
   * @return {void}
   */
  'POST/invoice_expense_vouchers/return_travel': (params: any, extra?: Extra) => {
    return axios(`/invoice_expense_vouchers/return_travel`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取单据审批人下拉
   * @return {void}
   */
  'GET/users/lease_approval': () => {
    return axios.get<any>(`/users/lease_approval`)
  },

  /**
   * @description: 生成凭证记录
   * @return {void}
   */
  'POST/invoice_expense_vouchers/generate_voucher': (params: any, extra?: Extra) => {
    return axios(`/invoice_expense_vouchers/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 发票凭证-修改备注
   * @param {number} id
   * @param {string} note 备注
   * @return {void}
   */
  'PATCH/invoices/update_info': (params: any, extra?: Extra) => {
    return axios(`/invoices/update_info`, preParams(params, extra, 'patch'))
  },

  
  /**
   * @description: 别事项列表(过滤空purpose)
   * @return {void}
   */
  'GET/invoice_expense_categorys/filterCategory': () => {
    return axios.get<any>(`/invoice_expense_categorys/filterCategory`)
  },
  /**
   * @description: 获取年假可换薪记录
   * @return {void}
   */
  'GET/checkin_balances/annual_salary': () => {
    return axios.get<any>(`/checkin_balances/annual_salary`)
  },
  
  /**
   * @description: 获取所有公司
   * @return {void}
   */
  'GET/companies/notCancelCompany': () => axios.get(`/companies/notCancelCompany`),

  /**
   * @description: 获取开票结算组织
   * @return {void}
   */
  'GET/invoice_expense_vouchers/payee_company': () => axios.get(`/invoice_expense_vouchers/payee_company`),
}