import axios from '@/http/index'
import {preParams, Extra} from "../index";
// 询证函
export default {
  /**
   * @description: 新增应付询证函
   * @return {void}
  */
  'POST/cost/confirmation/create': (params: any, extra?: Extra) => {
    return axios(`/cost/confirmation/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 检查新增应付询证函
   * @return {void}
  */
  'POST/cost/confirmation/createCheck': (params: any, extra?: Extra) => {
    return axios(`/cost/confirmation/createCheck`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 应付询证函查询
   * @return {void}
  */
  'POST/cost/confirmation/page': (params: any, extra?: Extra) => {
    return axios(`/cost/confirmation/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 查询询证函（导出）
   * @return {void}
  */
  'POST/cost/confirmation/export': (params: any, extra?: Extra) => {
    return axios(`/cost/confirmation/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除付款询证函
   * @param {array} selectIds
   * @return {void}
  */
  'POST/cost/confirmation/delete': (params: {selectIds: number[]}, extra?: Extra) => {
    return axios(`/cost/confirmation/delete`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 锁定/解锁
   * @param {array} selectIds
   * @return {void}
  */
  'POST/cost/confirmation/lock': (params: {selectIds: number[]}, extra?: Extra) => {
    return axios(`/cost/confirmation/lock`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 生成或清除询证函序号
   * @param {string} type
   * @return {void}
  */
  'PUT/cost/confirmation/create_or_clear_no': (params: {type: string}, extra?: Extra) => {
    return axios(`/cost/confirmation/create_or_clear_no`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 创建询证函文件
   * @param {array} selectIds
   * @return {void}
  */
  'POST/cost/confirmation/create_file': (params: {selectIds: number[]}, extra?: Extra) => {
    return axios(`/cost/confirmation/create_file`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 下载询证函文件
   * @param {array} selectIds
   * @return {void}
  */
  'POST/cost/confirmation/download_file': (params: {selectIds: number[]}, extra?: Extra) => {
    return axios(`/cost/confirmation/download_file`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新未锁定询证函信息
   * @param {string} year
   * @return {void}
  */
  'PUT/cost/confirmation/update_info/{year}': ({year}: {year: string}, extra?: Extra) => {
    return axios(`/cost/confirmation/update_info/${year}`, preParams({}, extra, 'put'))
  },

  /**
   * @description: 询证函合并
   * @param {array} selectIds
   * @return {void}
  */
  'POST/confirmation/merge': (params: {selectIds: number[]}, extra?: Extra) => {
    return axios(`/confirmation/merge`, preParams(params, extra, 'post'))
  },

  /**
   * @description:  询证函导入修改发送日期、合格标志
   * @param {object} file
   * @return {void}
  */
  'POST/confirmation/import/update': (params: any, extra?: Extra) => {
    return axios(`/confirmation/import/update`, preParams(params, extra, 'post'))
  },
}