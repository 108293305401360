import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { ElMessage } from 'element-plus'
import store from "@/store/index";
// import { MessageBox, Message } from "element-ui";
// import { getToken } from "@/utils/auth";

export let axiosInstance = axios

// create an axios instance
const service = axios.create({
  // headers: "ContentType: 'application/json;charset=UTF-8'",
  timeout: 30000,
})

// request interceptor
service.interceptors.request.use((config: any) => {

  config.headers['X-auth-token'] = store.getters.token
  config.baseURL = localStorage.getItem('environment') === '1' ? '/devapi/' : '/testapi/'
  // config.baseURL = 'http://192.168.1.109:8800/api'
    if(process.env.VUE_APP_ENV==="local"){
        config.baseURL = '/localapi/'
    }
  if( process.env.NODE_ENV !== 'development'){
    config.baseURL = '/api/'
  }

  return config;
},
(error) => {
  console.log(error)
  return Promise.reject(error);
})

// response interceptor
service.interceptors.response.use((response) => {
  // const resp = (['0', 0].includes(response.data) || response.data) ? response.data :  response
  const resp = response?.data

  resp.message && ElMessage.success(resp.message)

  // ['0', 0].includes(response.data) ?

  if(response.headers['content-disposition'] || response.headers['content-type'] === 'content-type: application/octet-stream;charset=UTF-8'){
    // 如果返回的是流
    return response
  }
  return resp

},
(error) => {
  if(error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")){
    ElMessage({ message: '请求超时', type: "warning", grouping: true })
    return Promise.reject(error)
  }

  let { status } = error.response

  switch (status) {
    case 502:
      ElMessage({
        message: '系统更新中，请稍等',
        type: "error",
        duration: 5000,
      });
      return Promise.reject(error);
    case 401:
      // login out
      // 处理path, query

      /* let redirectQueryPath = ''
      let redirectQueryArr = Object.keys(store.getters.currentRoute.query)

      if(redirectQueryArr.length !== 0){
        redirectQueryPath = '&'
        for (let index = 0; index < redirectQueryArr.length; index++) {
          redirectQueryPath += redirectQueryArr[index] + '=' + store.getters.currentRoute.query[redirectQueryArr[index]]
          if(index + 1 !== redirectQueryArr.length){
            redirectQueryPath += '&'
          }
        }
      } */
      store.dispatch('logout', store.getters.currentRoute)
      return Promise.reject(error);

    default:
      if(error.response.data.message || error.response.data.code === 'Error'){

        ElMessage({
          message: error.response.data.message || '服务器错误',
          type: "error",
          duration: 5000,
        });
      } /* else {
        ElMessage.error('服务器错误或者正在更新，请稍候')
      } */
      return Promise.reject(error);
      break;
  }

  let strs: string = Object.values(error.response.data).reduce((str: string, _err: any)=>{
    str += (_err && ( _err.message || _err[0].message ) + '<br>' )
    return str
  }, '')
  if(strs.length > 0){

    ElMessage({
      dangerouslyUseHTMLString: true,
      message: strs,
      type: "warning",
      duration: 5000,
    })
  }
  return Promise.reject(error);
})

export default service;